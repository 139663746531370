// Inspiração - http://www.google.com/design/spec/style/color.html#color-color-palette
// Gerador de cores - http://tools.quatrodigital.com.br/colors/#colors=%23008ED1,%23777777,%23007DB8,%23D8D8D8,%23777777,%23111111,%23FFFFFF,%23FFFFFF,%23FFFFFF,%23FFFFFF,%23111111,%23000000

$color-1: #00aeef; // Cor principal da marca
$color-2: #777777; // Cor secundária da marca
$color-3: #009ad9; // Cor do callToAction Principal (ex.: botão comprar)
$color-4: #D8D8D8; // CallToAction Secundário (ex.: botão de newsletter)
$color-5: #777777; // Cor livre
$color-6: #111111; // Cor Neutra (ex.: cinza)
$text-color-1: #111111; // Cor para textos

// Cor 1
$color-1-50: lighten($color-1, 20%);
$color-1-100: lighten($color-1, 16%);
$color-1-200: lighten($color-1, 12%);
$color-1-300: lighten($color-1, 8%);
$color-1-400: lighten($color-1, 4%);
$color-1-500: $color-1;
$color-1-600: darken($color-1, 5%);
$color-1-700: darken($color-1, 10%);
$color-1-800: darken($color-1, 15%);
$color-1-900: darken($color-1, 20%);
// Cor 2
$color-2-50: lighten($color-2, 20%);
$color-2-100: lighten($color-2, 16%);
$color-2-200: lighten($color-2, 12%);
$color-2-300: lighten($color-2, 8%);
$color-2-400: lighten($color-2, 4%);
$color-2-500: $color-2;
$color-2-600: darken($color-2, 5%);
$color-2-700: darken($color-2, 10%);
$color-2-800: darken($color-2, 15%);
$color-2-900: darken($color-2, 20%);
// Cor 3
$color-3-50: lighten($color-3, 20%);
$color-3-100: lighten($color-3, 16%);
$color-3-200: lighten($color-3, 12%);
$color-3-300: lighten($color-3, 8%);
$color-3-400: lighten($color-3, 4%);
$color-3-500: $color-3;
$color-3-600: darken($color-3, 5%);
$color-3-700: darken($color-3, 10%);
$color-3-800: darken($color-3, 15%);
$color-3-900: darken($color-3, 20%);
// Cor 4
$color-4-50: lighten($color-4, 20%);
$color-4-100: lighten($color-4, 16%);
$color-4-200: lighten($color-4, 12%);
$color-4-300: lighten($color-4, 8%);
$color-4-400: lighten($color-4, 4%);
$color-4-500: $color-4;
$color-4-600: darken($color-4, 5%);
$color-4-700: darken($color-4, 10%);
$color-4-800: darken($color-4, 15%);
$color-4-900: darken($color-4, 20%);
// Cor 5
$color-5-50: lighten($color-5, 20%);
$color-5-100: lighten($color-5, 16%);
$color-5-200: lighten($color-5, 12%);
$color-5-300: lighten($color-5, 8%);
$color-5-400: lighten($color-5, 4%);
$color-5-500: $color-5;
$color-5-600: darken($color-5, 5%);
$color-5-700: darken($color-5, 10%);
$color-5-800: darken($color-5, 15%);
$color-5-900: darken($color-5, 20%);
// Cor 6
$color-6-50: lighten($color-6, 20%);
$color-6-100: lighten($color-6, 16%);
$color-6-200: lighten($color-6, 12%);
$color-6-300: lighten($color-6, 8%);
$color-6-400: lighten($color-6, 4%);
$color-6-500: $color-6;
$color-6-600: darken($color-6, 5%);
$color-6-700: darken($color-6, 10%);
$color-6-800: darken($color-6, 15%);
$color-6-900: darken($color-6, 20%);
// Textos - alpha
$text-color-1-500: $text-color-1;
$text-color-1-400: lighten($text-color-1, 26%);
$text-color-1-300: lighten($text-color-1, 54%);
$text-color-1-200: lighten($text-color-1, 68%);
$text-color-1-100: lighten($text-color-1, 82%);



// Alertas
$close-1: #5A5A5A; // Cinza Escuro para botões fechar (normalmente modal)
$alert-1: #A1834A; // Amarelo padrão para bg de texto de aviso
$alert-2: #F7F2D0; // Amarelo claro normalmente texto de aviso
$error-1: #c34e4c; // vermelho padrão para elementos de erro
$error-2: #eac2be; // vermelho padrão para bg de texto de erro
$success-1: #007E21; // Verde padrão para elementos de suceso
$success-2: #D6F2DD; // Verde claro normalmente para bg de texto de suceso
$info-1: #457CAD; // Azul padrão para bg de texto de informação
$info-2: #C7E5F4; // Azul claro normalmente texto de informação

// COLOR AJAX LOADER
$ajax-color: #fff6bf; // Azul claro normalmente texto de informação

// Login
$facebook-login-btn: #475f96;
$google-login-btn: #EA4335;
$password-login-btn: $color-1-500; // não altera ow infeliz
$pin-login-btn: $color-3; // não altera ow infeliz

// Emement Colors
$body-color: #FFF; // Background do site
$white: #FFF;
$black: #000;

// shelf__flag colors
$color-shelf__flag-shipping: #2DB9CC; //frete
$color-shelf__flag-new: #008ED1; //novos, lançamentos, novidades e pré vendas
$color-shelf__flag-discount: #27AE60; //descontos e promoções

// Contrast Color Text
$color-contrast-dark: $text-color-1-500;
$color-contrast-light: $white;
$color-contrast-threshold: 58%;
// Exemplo:
// color: contrast(cor, $color-contrast-dark, $color-contrast-light, $color-contrast-threshold);

$color-c-100: #EEEEEE;
$color-c-200: #757575;
$color-c-300: #E50000;