@import "./general";

.tipBar {
  padding: 10px;

  &__icon {
    display: inline-block;
    
    i {
      font-size: 25px;
      color: $color-1-500;
    }
  }

  &__textsFooter {
    vertical-align: top;
    padding: 2px 0 0 5px;

    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1;
      color: #777777;
    }
  }

  &__texts {
    display: inline-block;
    vertical-align: top;
    padding: 2px 0 0 5px;

    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1;
      color: #777777;
    }
  }
}

.blkTipBox {
  border: solid 1px lightgray;
  border-radius: 5px;
  align-content: center;
}