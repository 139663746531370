@import './general';

/*CSS Novo - maio/22*/

.container-login {
  background-color: #0c5091;
  min-width: 100%;
  min-height: 100%;

}

.section-login-filial {
  background-image: url('../img/fundo_login_1.jpg');
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  animation: change 10s infinite ease-in;
}

.painelLogo-nome {
  background: url('../img/logo-grupo-canopus.png');
  position: absolute;
  background-position: 3% 90%;
  background-repeat: no-repeat;
  background-size: 20%;
  height: 100%;
  width: 100%;

}

.painel-login-filial {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  position: relative;
  letter-spacing: 2px;
  justify-content: flex-end;
  min-height: 93%;
  margin-left: -4%;
}


.btn-filial {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.btn-painel-filial {
  font-size: 25px;
  text-decoration: none;
  color: #fff;
  padding: 12px 0;
  max-width: 20%;
  width: 100%;
  height: 60%;
  background-color: #0093d6e6;
  border-color: rgb(12, 80, 145);
  border: solid rgb(12, 80, 145);
  border-radius: 20px;
  margin-left: 2%;
}

.btn-painel-filial:hover {
  color: rgb(15, 125, 228);
  border: solid rgb(15, 125, 228);
  text-decoration: none;
  border-radius: 5%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(16, 16, 16, 0.4);
  border-radius: 20px;
}

.spinner-center {
  text-align: center !important;
  color: #fff;
  margin-top: -2%;
}


@keyframes change {
  0% {
    background-image: url(../img/fundo_login_1.jpg);
  }

  40% {
    background-image: url(../img/fundo_login_2.jpg);
  }

  55% {
    background-image: url(../img/fundo_login_1.jpg);
  }

  100% {
    background-image: url(../img/fundo_login_2.jpg);
  }
}


@media screen and (max-width: 1100px) {
  .painel-login-filial {
    min-height: 83%;
  }

  .painelLogo-nome {
    background-position: 3% 79%;
  }

  .btn-painel-filial {
    font-size: 15px;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
  .painel-login-filial {
    min-height: 86%;
  }

  .painelLogo-nome {
    background-position: 3% 83%;
  }

  .btn-painel-filial {
    font-size: 16px;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1550px) {
  .btn-painel-filial {
    font-size: 18px;
  }
}