@import "./general";

/* Header */

.header {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: all ease 0.4s;
  background-color: $white;
  // padding: 10px;

  @include respond-above(md) {
    padding: 0;
  }

  .icon-submit {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    color: #0f61ac;;
  }
}

.header__link {
  color: $color-contrast-light;

  &:hover,
  &:focus,
  &:active {
    color: $color-contrast-light;
  }
}

/* End Header */


.row {
  justify-content: center;
}

.box {
  margin: 15px 0;
  padding: 20px;
}

.card-item {
  padding: 10px;
  background: $white;
  margin: 10px 5px;
  flex: 1 0 auto;

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 2px rgba(239,239,239,1);
    -moz-box-shadow: 0px 0px 1px 2px rgba(239,239,239,1);
    box-shadow: 0px 0px 1px 2px rgba(239,239,239,1);
  }

  &--titulo {
    height: 30px;
    overflow: hidden;
    word-break: break-word;
  }

  &--valor {
    font-weight: bold;
  }

  &--counter {
    max-width: 80px;
    @include border($color-4-500, 1px);
  }
}

.form-adicionar {
  display: flex;
  justify-content: center;
  align-items: center;
  @include border(2px, $color-4-500, solid);

  &--btn {
    font-size: 1.5rem;
    border-radius: 0;
    @include border($color-1-500, 2px);
    height: 25px;
    width: 25px;
    padding: 6px;
    color: $color-1-500;

    &:hover,
    &:focus,
    &:active {
        color: $color-1-500;
        @include border(2px, $color-1-500, solid);
        background-color: $white;
    }
  }
}

._button {
  @extend %button-main;
  padding: 15px 5px;
  font-size: 1rem;
}

.no-stock {
  font-size: 16px;
  color: $color-contrast-dark;
  font-weight: bold;
  line-height: 100px;
}